<template>
  <div style="height: 100%; overflow: auto">
    <el-form
      style="height: 100%; overflow: auto"
      :model="dataForm"
      :rules="rules"
      ref="dataForm"
      ><AssociationScroll>
        <AssociationScrollView title="保单信息" name="0">
          <TextInputContainer>
            <el-form-item prop="policyNo">
              <TextInput
                :isText="!isEdit"
                @handleSelectQuerySearchAsync="handleSelectSearchAsync"
                :querySearchAsync="querySearchAsync"
                v-model="dataForm.policyNo"
                :config="{
                  type: 'searchInput',
                  required: true,
                  label: '保单号：',
                }"
              >
              </TextInput>
            </el-form-item>
            <TextInput
              :isText="!isEdit"
              v-for="(config, index) in baseItemsConfig"
              :key="index"
              :config="config"
              v-model="dataForm[config.modelKey]"
            ></TextInput>
          </TextInputContainer>
        </AssociationScrollView>
        <AssociationScrollView title="出险信息" name="1">
          <TextInputContainer>
            <template v-for="(config, index) in accidentItemsConfig">
              <el-form-item :prop="config.modelKey">
                <TextInput
                  :isText="!isEdit"
                  @returnVal="getKeyToValue"
                  :key="index"
                  :config="config"
                  :optionConfig="{
                    value: 'dictKey',
                    label: 'dictValue',
                  }"
                  v-model="dataForm[config.modelKey]"
                ></TextInput>
              </el-form-item>
            </template>
          </TextInputContainer>
          <TextInputContainer>
            <el-form-item prop="isInsuranceLiability"
              ><TextInput
                :isText="!isEdit"
                :config="{
                  label: '是否构成保险责任：',
                  type: 'radio',
                  option: dictList.isOrNot,
                  modelKey: 'isInsuranceLiability',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                v-model="dataForm.isInsuranceLiability"
              ></TextInput
            ></el-form-item>
            <el-form-item v-if="!dataForm.isInsuranceLiability">
              <TextInput
                :isText="!isEdit"
                v-model="dataForm.isInsuranceLiabilityCause"
                :config="{
                  label: '不构成保险责任原因：',
                  type: 'input',
                }"
              ></TextInput>
            </el-form-item>
          </TextInputContainer>
          <TextInputContainer>
            <el-form-item prop="isOffence"
              ><TextInput
                :isText="!isEdit"
                :config="{
                  label: '案件当事人有无违法行为：',
                  type: 'radio',
                  option: dictList.isOrNot,
                  modelKey: 'isOffence',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                v-model="dataForm.isOffence"
              ></TextInput
            ></el-form-item>
            <el-form-item v-if="dataForm.isOffence">
              <TextInput
                :isText="!isEdit"
                v-model="dataForm.isOffenceCause"
                :config="{
                  label: '当事人违法行为：',
                  type: 'input',
                }"
              ></TextInput>
            </el-form-item>
          </TextInputContainer>
          <TextInputContainer>
            <el-form-item prop="moralHazard"
              ><TextInput
                :isText="!isEdit"
                :config="{
                  label: '是否存在道德风险：',
                  type: 'radio',
                  option: dictList.isOrNot,
                  modelKey: 'moralHazard',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                v-model="dataForm.moralHazard"
              ></TextInput
            ></el-form-item>
            <el-form-item v-if="dataForm.moralHazard">
              <TextInput
                :isText="!isEdit"
                v-model="dataForm.potentialMoralHazards"
                :config="{
                  label: '已知可能存在的道德风险：',
                  type: 'input',
                }"
              ></TextInput>
            </el-form-item>
          </TextInputContainer>
          <TextInputContainer>
            <el-form-item prop="isOperatingAddress"
              ><TextInput
                :isText="!isEdit"
                :config="{
                  label: '是否生产经营地址内出险：',
                  type: 'radio',
                  option: dictList.isOrNot,
                  modelKey: 'isOperatingAddress',
                }"
                :optionConfig="{
                  value: 'dictKey',
                  label: 'dictValue',
                }"
                v-model="dataForm.isOperatingAddress"
              ></TextInput
            ></el-form-item>
          </TextInputContainer>
        </AssociationScrollView>
        <AssociationScrollView title="赔付信息" name="2">
          <div class="total">
            <div class="total-title">赔付总金额(元)：</div>
            <div class="total-value">
              {{ addThousandSeparator(compensationTotalAmount) }}
            </div>
          </div>
          <div style="margin-top: 24px">
            <el-table
              :data="dataForm.insAzxCompensationReqList"
              style="width: 100%"
              :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
            >
              <el-table-column
                type="index"
                label="序号"
                width="55"
              ></el-table-column>
              <el-table-column prop="compensationTypeKey" label="赔付项种类">
                <template slot-scope="{ row }">
                  <TextInput
                    :isText="!isEdit"
                    @returnVal="getKeyToValue($event, row)"
                    :config="{
                      type: 'select',
                      option: dictList.CompensationType,
                      modelKey: 'isOperatingAddress',
                      modelTextKey: 'compensationType',
                      width: '100%',
                    }"
                    :optionConfig="{
                      value: 'dictKey',
                      label: 'dictValue',
                    }"
                    v-model="row.compensationTypeKey"
                  ></TextInput>
                </template>
              </el-table-column>
              <el-table-column prop="compensationName" label="赔付项名称">
                <template slot-scope="{ row }">
                  <TextInput
                    :isText="!isEdit"
                    :config="{
                      type: 'input',
                      modelKey: 'compensationName',
                      width: '100%',
                    }"
                    v-model="row.compensationName"
                  ></TextInput>
                </template>
              </el-table-column>
              <el-table-column prop="compensationAmount" label="赔付金额"
                ><template slot-scope="{ row }">
                  <TextInput
                    :isText="!isEdit"
                    :config="{
                      type: 'input',
                      modelKey: 'compensationAmount',
                      width: '100%',
                      isNum: true,
                    }"
                    v-model="row.compensationAmount"
                  ></TextInput> </template
              ></el-table-column>
              <el-table-column prop="payeeName" label="收款方"
                ><template slot-scope="{ row }">
                  <TextInput
                    :isText="!isEdit"
                    :config="{
                      type: 'input',
                      modelKey: 'payeeName',
                      width: '100%',
                    }"
                    v-model="row.payeeName"
                  ></TextInput> </template
              ></el-table-column>
              <el-table-column prop="payeeType" label="收款方类型"
                ><template slot-scope="{ row }">
                  <TextInput
                    :isText="!isEdit"
                    :config="{
                      type: 'input',
                      modelKey: 'payeeType',
                      width: '100%',
                    }"
                    v-model="row.payeeType"
                  ></TextInput> </template
              ></el-table-column>
              <el-table-column prop="compensationDate" label="付款时间"
                ><template slot-scope="{ row }">
                  <TextInput
                    :isText="!isEdit"
                    :config="{
                      type: 'datePicker',
                      modelKey: 'compensationDate',
                      width: '100%',
                    }"
                    v-model="row.compensationDate"
                  ></TextInput> </template
              ></el-table-column>
              <el-table-column prop="remark" label="备注"
                ><template slot-scope="{ row }">
                  <TextInput
                    :isText="!isEdit"
                    :config="{
                      type: 'input',
                      modelKey: 'remark',
                      width: '100%',
                    }"
                    v-model="row.remark"
                  ></TextInput> </template
              ></el-table-column>
              <el-table-column v-if="isEdit" label="操作">
                <template slot-scope="{ $index }">
                  <el-button
                    style="color: #f64a2d"
                    v-if="$index"
                    type="text"
                    @click="handleDelete($index)"
                    >删除</el-button
                  >
                  <el-button v-else type="text">---</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="isEdit" class="table-add-btn" @click="addTableItem">
              添加赔付
            </div>
            <!-- 用于显示校验错误信息 -->
            <el-form-item prop="insAzxCompensationReqList"> </el-form-item>
          </div>
        </AssociationScrollView>
        <AssociationScrollView title="影像记录" name="3">
          <FormListUpload
            :isEdit="isEdit"
            title=""
            :limit="5"
            :limitSize="50"
            :limitType="['jpg', 'jpeg', 'png', 'mp4']"
            tips="只支持.jpg/.jpeg/.png/.mp4格式，单个文件不超过50MB，最多上传5个文件"
            attachmentType="sd_claim_file"
            v-model="dataForm.attachmentAddReqList"
          ></FormListUpload>
        </AssociationScrollView>
        <AssociationScrollView title="规避措施" name="4">
          <div style="max-width: 980px">
            <TextInput
              :isText="!isEdit"
              v-for="(config, index) in measureItemConfig"
              :key="index"
              :config="config"
              v-model="dataForm[config.modelKey]"
            ></TextInput>
          </div>
        </AssociationScrollView> </AssociationScroll
    ></el-form>
    <div class="sd-bottom-btn">
      <el-button @click="back">返回</el-button>
      <el-button v-if="isEdit" type="primary" @click="submitForm"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import AssociationScroll from "@/components/AssociationScroll/AssociationScroll.vue";
import AssociationScrollView from "@/components/AssociationScroll/AssociationScrollView.vue";
import TextInput from "../components/TextInput";
import TextInputContainer from "../components/TextInputContainer";
import SimpleTable from "../components/SimpleTable";
import FormListUpload from "@/components/FormListUpload/index.vue";
import { dictionaryBatch } from "@/api/policy";
import { addThousandSeparator } from "@/utils/dealNumber";
import { verifyArryEmpty } from "../js/verify.js";
import {
  addOrEditInsuranceClaim,
  getInsuranceCompanyByPolicyNo,
} from "@/api/safeDuty.js";
export default {
  components: {
    AssociationScroll,
    AssociationScrollView,
    TextInputContainer,
    TextInput,
    SimpleTable,
    FormListUpload,
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    detailData(n) {
      if (n) {
        this.dataForm = { ...this.dataForm, ...n };
        this.$forceUpdate();
      }
    },
  },
  computed: {
    compensationTotalAmount() {
      let amount = 0;
      this.dataForm?.insAzxCompensationReqList?.forEach((item) => {
        amount += Number(item.compensationAmount) || 0;
      });
      return amount;
    },
  },
  data() {
    return {
      addThousandSeparator: addThousandSeparator,
      dictList: {
        isOrNot: [
          {
            dictKey: true,
            dictValue: "是",
          },
          {
            dictKey: false,
            dictValue: "否",
          },
        ],
      },
      rules: {
        policyNo: [
          { required: true, message: "请选择保单号", trigger: "change" },
        ],
        realCaseNo: [
          { required: true, message: "请输入报案号", trigger: "change" },
        ],
        caseOfficer: [
          { required: true, message: "请输入案件负责", trigger: "change" },
        ],
        appraisalAgencyKey: [
          { required: true, message: "请选择理赔公估机构", trigger: "change" },
        ],
        dangerTypeKey: [
          { required: true, message: "请选择出险类型", trigger: "change" },
        ],
        accidentTypeKey: [
          { required: true, message: "请选择事故类型", trigger: "change" },
        ],
        dangerDetailAddress: [
          { required: true, message: "请选择出险地点", trigger: "change" },
        ],
        dangerDate: [
          { required: true, message: "请选择出险时间", trigger: "change" },
        ],
        accidentCause: [
          { required: true, message: "请输入简述事故经过", trigger: "change" },
        ],
        insAzxCompensationReqList: [
          {
            validator: verifyArryEmpty,
            message: "请添加赔付信息",
            trigger: "blur",
          },
        ],
      },
      dataForm: {
        accidentCause: "",
        accidentType: "",
        accidentTypeKey: "",
        accidentTypeValue: "",
        appraisalAgency: "",
        appraisalAgencyKey: "",
        appraisalAgencyValue: "",
        attachmentAddReqList: [
          // {
          //   attachmentName: "",
          //   attachmentType: "",
          //   attachmentUrl: "",
          //   fileName: "",
          //   fileType: "",
          // },
        ],
        avoidanceMeasures: "",
        caseNo: "",
        caseOfficer: "",
        caseProgressStatus: "",
        caseProgressStatusKey: "",
        caseProgressStatusValue: "",
        causeOfAccident: "",
        compensationTotalAmount: "",
        dangerDate: "",
        dangerDetailAddress: "",
        dangerType: "",
        dangerTypeKey: "",
        dangerTypeValue: "",
        isOperatingAddress: "",
        insAzxCompensationReqList: [
          // {
          //   compensationAmount: "0",
          //   compensationDate: "",
          //   compensationName: "",
          //   compensationType: "",
          //   compensationTypeKey: "",
          //   compensationTypeValue: "",
          //   payeeName: "",
          //   payeeType: "",
          //   remark: "",
          // },
        ],
        insuranceCompanyName: "",
        insuredId: "",
        insuredName: "",
        isDeleted: "",
        isInsuranceLiability: true,
        isInsuranceLiabilityCause: "",
        isOffence: true,
        isOffenceCause: "",
        moralHazard: true,
        policyHolderId: "",
        policyHolderName: "",
        policyId: "",
        policyInsuranceType: "",
        policyNo: "",
        potentialMoralHazards: "",
        realCaseNo: "",
        responsibleParty: "",
      },
      baseItemsConfig: [
        {
          label: "被保险人：",
          type: "input",
          disabled: true,
          placeholder: "根据保单号自动带出，无需填写",
          modelKey: "insuredName",
        },
        {
          label: "险种名称：",
          type: "input",
          disabled: true,
          placeholder: "根据保单号自动带出，无需填写",
          modelKey: "policyInsuranceType",
        },
        {
          label: "保险公司：",
          type: "input",
          disabled: true,
          placeholder: "根据保单号自动带出，无需填写",
          modelKey: "insuranceCompanyName",
        },
      ],
      accidentItemsConfig: [],
      measureItemConfig: [
        {
          label: "事故原因：",
          type: "textarea",
          modelKey: "causeOfAccident",
          width: "100%",
        },
        {
          label: "规避措施：",
          type: "textarea",
          modelKey: "avoidanceMeasures",
          width: "100%",
        },
      ],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getDictionarySd();
    },
    getDictionarySd() {
      let dictKeys = [
        "ClaimsAdjuster",
        "CaseProgressStatus",
        "accidentType",
        "CompensationType",
        "dangerType",
      ];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.$set(this.dictList, key, res.data[key] || []);
          });
        }
        this.setConfig();
      });
    },
    setConfig() {
      this.accidentItemsConfig = [
        {
          label: "报案号：",
          type: "input",
          required: true,
          modelKey: "realCaseNo",
        },
        {
          label: "案件负责人：",
          type: "input",
          required: true,
          modelKey: "caseOfficer",
        },
        {
          label: "理赔公估机构：",
          type: "select",
          required: true,
          modelKey: "appraisalAgencyKey",
          modelTextKey: "appraisalAgency",
          option: this.dictList.ClaimsAdjuster,
        },
        {
          label: "出险类型：",
          type: "select",
          required: true,
          modelKey: "dangerTypeKey",
          modelTextKey: "dangerType",
          option: this.dictList.dangerType,
        },
        {
          label: "事故类型：",
          type: "select",
          required: true,
          modelKey: "accidentTypeKey",
          modelTextKey: "accidentType",
          option: this.dictList.accidentType,
        },
        {
          label: "出险地点：",
          type: "location",
          modelKey: "dangerDetailAddress",
          required: true,
        },
        {
          label: "出险时间：",
          type: "datePicker",
          required: true,
          modelKey: "dangerDate",
        },
        {
          label: "简述事故经过：",
          type: "input",
          required: true,
          modelKey: "accidentCause",
        },
        {
          label: "案件进展状态：",
          type: "select",
          required: true,
          modelKey: "caseProgressStatusKey",
          modelTextKey: "caseProgressStatus",
          option: this.dictList.CaseProgressStatus,
        },
        {
          label: "责任方：",
          type: "input",
          modelKey: "responsibleParty",
        },
      ];
    },
    setPolicyData(res, init = false) {
      let keys = [
        ["insuredName", "insuredName"],
        ["insuredId", "insuredId"],
        ["coverageName", "policyInsuranceType"],
        ["insuranceCompanyName", "insuranceCompanyName"],
      ];
      keys.forEach((key) => {
        if (init) {
          this.$set(this.dataForm, key[1], "");
        } else {
          this.$set(this.dataForm, key[1], res[key[0]]);
        }
      });
    },
    handleSelectSearchAsync(e) {
      this.setPolicyData(e);
      this.dataForm.policyId = e.id;
    },
    getKeyToValue(res, res2) {
      if (res.comType == "select") {
        if (res2) {
          res2[res.type] = res.data.dictValue;
        } else {
          this.dataForm[res.type] = res.data.dictValue;
        }
      }
    },
    querySearchAsync(queryString, cb) {
      if (!queryString) {
        cb([]);
        return;
      }
      getInsuranceCompanyByPolicyNo({ policyName: queryString }).then(
        (res) => {
          let results = res.data.map((item) => {
            return {
              ...item,
              value: item.policyNumber,
            };
          });
          cb(results);
        },
        () => {
          cb([]);
        }
      );
    },
    addTableItem() {
      this.dataForm.insAzxCompensationReqList.push({
        compensationAmount: 0,
        compensationDate: "",
        compensationName: "",
        compensationType: "",
        compensationTypeKey: "",
        compensationTypeValue: "",
        payeeName: "",
        payeeType: "",
        remark: "",
      });
    },
    handleDelete(i) {
      this.dataForm.insAzxCompensationReqList.splice(i, 1);
    },
    submitForm() {
      this.dataForm.compensationTotalAmount = this.compensationTotalAmount;
      console.log(this.dataForm);
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          addOrEditInsuranceClaim(this.dataForm).then((res) => {
            this.$message({
              type: "success",
              message: "成功!",
            });
            this.back();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.replace({ path: this.$route.path });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-add-btn {
  background: #ffffff;
  border: 1px solid #dee3e6;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #00bc0d;
  cursor: pointer;
}
.sd-bottom-btn {
  width: 100%;
  height: 80px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px;
  z-index: 99;
}
.total {
  display: flex;
  align-items: center;
  margin: 16px 0;
  &-ttitle {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
  &-value {
    font-weight: bold;
    font-size: 18px;
    color: #3470ff;
  }
}
</style>
