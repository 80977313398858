var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%","overflow":"auto"}},[_c('el-form',{ref:"dataForm",staticStyle:{"height":"100%","overflow":"auto"},attrs:{"model":_vm.dataForm,"rules":_vm.rules}},[_c('AssociationScroll',[_c('AssociationScrollView',{attrs:{"title":"保单信息","name":"0"}},[_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"policyNo"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"querySearchAsync":_vm.querySearchAsync,"config":{
                type: 'searchInput',
                required: true,
                label: '保单号：',
              }},on:{"handleSelectQuerySearchAsync":_vm.handleSelectSearchAsync},model:{value:(_vm.dataForm.policyNo),callback:function ($$v) {_vm.$set(_vm.dataForm, "policyNo", $$v)},expression:"dataForm.policyNo"}})],1),_vm._l((_vm.baseItemsConfig),function(config,index){return _c('TextInput',{key:index,attrs:{"isText":!_vm.isEdit,"config":config},model:{value:(_vm.dataForm[config.modelKey]),callback:function ($$v) {_vm.$set(_vm.dataForm, config.modelKey, $$v)},expression:"dataForm[config.modelKey]"}})})],2)],1),_c('AssociationScrollView',{attrs:{"title":"出险信息","name":"1"}},[_c('TextInputContainer',[_vm._l((_vm.accidentItemsConfig),function(config,index){return [_c('el-form-item',{attrs:{"prop":config.modelKey}},[_c('TextInput',{key:index,attrs:{"isText":!_vm.isEdit,"config":config,"optionConfig":{
                  value: 'dictKey',
                  label: 'dictValue',
                }},on:{"returnVal":_vm.getKeyToValue},model:{value:(_vm.dataForm[config.modelKey]),callback:function ($$v) {_vm.$set(_vm.dataForm, config.modelKey, $$v)},expression:"dataForm[config.modelKey]"}})],1)]})],2),_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"isInsuranceLiability"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '是否构成保险责任：',
                type: 'radio',
                option: _vm.dictList.isOrNot,
                modelKey: 'isInsuranceLiability',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              }},model:{value:(_vm.dataForm.isInsuranceLiability),callback:function ($$v) {_vm.$set(_vm.dataForm, "isInsuranceLiability", $$v)},expression:"dataForm.isInsuranceLiability"}})],1),(!_vm.dataForm.isInsuranceLiability)?_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '不构成保险责任原因：',
                type: 'input',
              }},model:{value:(_vm.dataForm.isInsuranceLiabilityCause),callback:function ($$v) {_vm.$set(_vm.dataForm, "isInsuranceLiabilityCause", $$v)},expression:"dataForm.isInsuranceLiabilityCause"}})],1):_vm._e()],1),_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"isOffence"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '案件当事人有无违法行为：',
                type: 'radio',
                option: _vm.dictList.isOrNot,
                modelKey: 'isOffence',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              }},model:{value:(_vm.dataForm.isOffence),callback:function ($$v) {_vm.$set(_vm.dataForm, "isOffence", $$v)},expression:"dataForm.isOffence"}})],1),(_vm.dataForm.isOffence)?_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '当事人违法行为：',
                type: 'input',
              }},model:{value:(_vm.dataForm.isOffenceCause),callback:function ($$v) {_vm.$set(_vm.dataForm, "isOffenceCause", $$v)},expression:"dataForm.isOffenceCause"}})],1):_vm._e()],1),_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"moralHazard"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '是否存在道德风险：',
                type: 'radio',
                option: _vm.dictList.isOrNot,
                modelKey: 'moralHazard',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              }},model:{value:(_vm.dataForm.moralHazard),callback:function ($$v) {_vm.$set(_vm.dataForm, "moralHazard", $$v)},expression:"dataForm.moralHazard"}})],1),(_vm.dataForm.moralHazard)?_c('el-form-item',[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '已知可能存在的道德风险：',
                type: 'input',
              }},model:{value:(_vm.dataForm.potentialMoralHazards),callback:function ($$v) {_vm.$set(_vm.dataForm, "potentialMoralHazards", $$v)},expression:"dataForm.potentialMoralHazards"}})],1):_vm._e()],1),_c('TextInputContainer',[_c('el-form-item',{attrs:{"prop":"isOperatingAddress"}},[_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                label: '是否生产经营地址内出险：',
                type: 'radio',
                option: _vm.dictList.isOrNot,
                modelKey: 'isOperatingAddress',
              },"optionConfig":{
                value: 'dictKey',
                label: 'dictValue',
              }},model:{value:(_vm.dataForm.isOperatingAddress),callback:function ($$v) {_vm.$set(_vm.dataForm, "isOperatingAddress", $$v)},expression:"dataForm.isOperatingAddress"}})],1)],1)],1),_c('AssociationScrollView',{attrs:{"title":"赔付信息","name":"2"}},[_c('div',{staticClass:"total"},[_c('div',{staticClass:"total-title"},[_vm._v("赔付总金额(元)：")]),_c('div',{staticClass:"total-value"},[_vm._v(" "+_vm._s(_vm.addThousandSeparator(_vm.compensationTotalAmount))+" ")])]),_c('div',{staticStyle:{"margin-top":"24px"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.dataForm.insAzxCompensationReqList,"header-cell-style":{ background: '#F5F7FA', color: '#606266' }}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"55"}}),_c('el-table-column',{attrs:{"prop":"compensationTypeKey","label":"赔付项种类"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                    type: 'select',
                    option: _vm.dictList.CompensationType,
                    modelKey: 'isOperatingAddress',
                    modelTextKey: 'compensationType',
                    width: '100%',
                  },"optionConfig":{
                    value: 'dictKey',
                    label: 'dictValue',
                  }},on:{"returnVal":function($event){return _vm.getKeyToValue($event, row)}},model:{value:(row.compensationTypeKey),callback:function ($$v) {_vm.$set(row, "compensationTypeKey", $$v)},expression:"row.compensationTypeKey"}})]}}])}),_c('el-table-column',{attrs:{"prop":"compensationName","label":"赔付项名称"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                    type: 'input',
                    modelKey: 'compensationName',
                    width: '100%',
                  }},model:{value:(row.compensationName),callback:function ($$v) {_vm.$set(row, "compensationName", $$v)},expression:"row.compensationName"}})]}}])}),_c('el-table-column',{attrs:{"prop":"compensationAmount","label":"赔付金额"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                    type: 'input',
                    modelKey: 'compensationAmount',
                    width: '100%',
                    isNum: true,
                  }},model:{value:(row.compensationAmount),callback:function ($$v) {_vm.$set(row, "compensationAmount", $$v)},expression:"row.compensationAmount"}})]}}])}),_c('el-table-column',{attrs:{"prop":"payeeName","label":"收款方"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                    type: 'input',
                    modelKey: 'payeeName',
                    width: '100%',
                  }},model:{value:(row.payeeName),callback:function ($$v) {_vm.$set(row, "payeeName", $$v)},expression:"row.payeeName"}})]}}])}),_c('el-table-column',{attrs:{"prop":"payeeType","label":"收款方类型"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                    type: 'input',
                    modelKey: 'payeeType',
                    width: '100%',
                  }},model:{value:(row.payeeType),callback:function ($$v) {_vm.$set(row, "payeeType", $$v)},expression:"row.payeeType"}})]}}])}),_c('el-table-column',{attrs:{"prop":"compensationDate","label":"付款时间"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                    type: 'datePicker',
                    modelKey: 'compensationDate',
                    width: '100%',
                  }},model:{value:(row.compensationDate),callback:function ($$v) {_vm.$set(row, "compensationDate", $$v)},expression:"row.compensationDate"}})]}}])}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('TextInput',{attrs:{"isText":!_vm.isEdit,"config":{
                    type: 'input',
                    modelKey: 'remark',
                    width: '100%',
                  }},model:{value:(row.remark),callback:function ($$v) {_vm.$set(row, "remark", $$v)},expression:"row.remark"}})]}}])}),(_vm.isEdit)?_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function({ $index }){return [($index)?_c('el-button',{staticStyle:{"color":"#f64a2d"},attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete($index)}}},[_vm._v("删除")]):_c('el-button',{attrs:{"type":"text"}},[_vm._v("---")])]}}],null,false,2055488059)}):_vm._e()],1),(_vm.isEdit)?_c('div',{staticClass:"table-add-btn",on:{"click":_vm.addTableItem}},[_vm._v(" 添加赔付 ")]):_vm._e(),_c('el-form-item',{attrs:{"prop":"insAzxCompensationReqList"}})],1)]),_c('AssociationScrollView',{attrs:{"title":"影像记录","name":"3"}},[_c('FormListUpload',{attrs:{"isEdit":_vm.isEdit,"title":"","limit":5,"limitSize":50,"limitType":['jpg', 'jpeg', 'png', 'mp4'],"tips":"只支持.jpg/.jpeg/.png/.mp4格式，单个文件不超过50MB，最多上传5个文件","attachmentType":"sd_claim_file"},model:{value:(_vm.dataForm.attachmentAddReqList),callback:function ($$v) {_vm.$set(_vm.dataForm, "attachmentAddReqList", $$v)},expression:"dataForm.attachmentAddReqList"}})],1),_c('AssociationScrollView',{attrs:{"title":"规避措施","name":"4"}},[_c('div',{staticStyle:{"max-width":"980px"}},_vm._l((_vm.measureItemConfig),function(config,index){return _c('TextInput',{key:index,attrs:{"isText":!_vm.isEdit,"config":config},model:{value:(_vm.dataForm[config.modelKey]),callback:function ($$v) {_vm.$set(_vm.dataForm, config.modelKey, $$v)},expression:"dataForm[config.modelKey]"}})}),1)])],1)],1),_c('div',{staticClass:"sd-bottom-btn"},[_c('el-button',{on:{"click":_vm.back}},[_vm._v("返回")]),(_vm.isEdit)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitForm}},[_vm._v("保存")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }