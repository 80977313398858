<template>
  <sdClaimForm
    :isEdit="$route.params.isEdit"
    :detailData="detailData"
    :id="this.$route.params.id"
  ></sdClaimForm>
</template>
<script>
import sdClaimForm from "./sdClaimForm.vue";
import { getInsuranceClaimDetail } from "@/api/safeDuty.js";
export default {
  components: { sdClaimForm },
  data() {
    return {
      detailData: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id);
    } else {
      this.detailData = null;
    }
  },
  methods: {
    async getDetail(id = "") {
      let res = await getInsuranceClaimDetail({
        id: this.$route.params.id,
      });
      this.detailData = res.data;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
